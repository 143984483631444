<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else fluid :dark="$dark.get()">
    <v-card-title class="header"
      >Управление филиалами (точками продаж)</v-card-title
    >
    <v-card-text v-if="profileData.id" class="pa-0">
      <div>
        
      </div>
      <section class="data">
        <!-- данные о филиале -->
        <div class="data_box">
          <div class="data_text">
            <p class="title">{{ departmentName }}</p>
            <p>
              <span>Успешных доставок: </span>{{ profileData.ordersDelivered }}
            </p>
            <p>
              <span>Доставлено вовремя заказов: </span
              >{{ profileData.deliveredOnTime }}
            </p>
            <p>
              <span class="red--text">Отмен: нет данных</span>
            </p>
            <p>
              <span>Рейтинг филиала: нет данных</span>
            </p>
          </div>
          <Diagrams
            :values="[profileData.ordersDelivered, profileData.deliveredOnTime]"
          />
        </div>

        <!-- контактные данные -->
        <div class="contacts input-type">
          <v-form ref="depForm">
            <div class="contacts_item3">
              <p>Почта для отчетов</p>
              <v-text-field
                ref="emailField"
                v-model="profileData.email"
                solo
                flat
                dense
                :rules="[$validate.required]"
                @input="showEditButton = true"
              />
              <v-btn
                class="icon1"
                icon
                large
                color="#5d4bd0"
                @click="highlightText('emailField')"
              >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <div class="contacts_item3">
              <p>Дополнительная почта</p>
              <v-text-field
                ref="emailFeedbackField"
                v-model="profileData.emailFeedback"
                solo
                dense
                flat
                :rules="[$validate.required]"
                @input="showEditButton = true"
              />
              <v-btn
                class="icon2"
                icon
                large
                color="#5d4bd0"
                @click="highlightText('emailFeedbackField')"
              >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <div class="contacts_item3">
              <p>Номер телефона филиала</p>
              <v-text-field
                ref="phoneNumberField"
                v-model="profileData.phoneNumber"
                v-mask="'+7 ### #######'"
                :rules="[$validate.required]"
                solo
                dense
                flat
                @input="showEditButton = true"
              />
              <v-btn
                class="icon2"
                icon
                large
                color="#5d4bd0"
                @click="highlightText('phoneNumberField')"
              >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </div>
            <v-btn
              v-if="showEditButton"
              small
              rounded
              color="#5d4bd0"
              class="contacts_btn"
              @click="editDepartmentFunc()"
            >
              Изменить данные
            </v-btn>
          </v-form>
          <div class="contacts_item">
            <p>Город</p>
            <v-text-field
              v-model="profileData.address.city.name"
              hide-details
              dense
              solo
              flat
            />
            <v-btn
              class="icon4 hidden-icon"

              icon
              large
              color="#5d4bd0"
              @click="highlightText('cityField')"
            >
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
          </div>
          <div class="contacts_item">
            <p>Улица</p>
            <v-text-field
              v-model="adressInp.street"
              hide-details
              dense
              solo
              flat
            />
            <v-btn
              class="icon5 hidden-icon"
              icon
              large
              color="#5d4bd0"
              @click="highlightText('streetField')"
            >
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
          </div>
          <div class="contacts_item">
            <p>Дом</p>
            <v-text-field

              v-model="adressInp.house"
              hide-details
              dense
              solo
              flat
            />
            <v-btn
              class="hidden-icon"
              icon
              large
              color="#5d4bd0"
              @click="highlightText('houseField')"
            >
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
          </div>
          <div class="contacts_item">
            <p>Комментарий курьеру</p>
            <v-btn
              v-if="editTextarea"
              small
              rounded
              class="contacts_btn"
              color="#5d4bd0"
              @click="editDepartmentFunc()"
            >
              Изменить комментарий
            </v-btn>
          </div>
          <v-textarea
            v-model="profileData.courierComment"
            auto-grow
            :label="!profileData.courierComment ? 'Комментарий не указан' : ''"
            rows="1"
            color="#51C8F0"
            solo
            hide-details
            class="textarea"
            @input="editTextarea = true"
          ></v-textarea>
        </div>

        <!-- блок с картинкой -->
        <div class="data_right-block">
          <div class="data_image">
            <input
              ref="profileFoto"
              type="file"
              hidden
              accept="image/*"
              @change="uploadFoto($event.target.files[0])"
            />
            <img
              :src="
                profileData.image
                  ? profileData.image.fileUrl
                  : '../../assets/placeholder.png'
              "
              width="250"
            />
            <span class="data_image__text"
              >Лого филиала отображается в магазине</span
            >
            <v-btn
              icon
              large
              color="#5d4bd0"
              class="data_image__btn"
              @click="$refs.profileFoto.click()"
              ><v-icon>mdi-pencil-circle</v-icon></v-btn
            >
          </div>
          <div class="data_requisits">
            <RequisitsBlock :requisits="requisits" :department="true" />
          </div>
        </div>
      </section>

      <section class="content">
        <div class="content_inputs">
          <!-- способы оплаты -->
          <div class="info-block">
            <span class="title">Управление типами оплат</span>
            <div class="contacts">
              <div
                v-for="payment in PAYMENT.allPayment"
                :key="payment.id"
                class="contacts_item2"
              >
                <p
                  class="contacts_text"
                  :class="checkSwitch(payment.id) && 'filled'"
                >
                  {{ payment.name }}
                </p>
                <v-switch
                  :input-value="checkSwitch(payment.id)"
                  color="#5d4bd0"
                  inset
                  hide-details
                  class="switch"
                  @change="changePaymentType(payment.id)"
                ></v-switch>
              </div>
            </div>
          </div>
          <!-- типы доставки -->
          <div class="contacts">
            <span class="title">Управление типами доставки</span>

            <div
              v-for="company in COMPANY"
              :key="company.id"
              class="contacts_box"
            >
              <div class="contacts_item2">
                <p class="contacts_text">
                  {{ company.name }}
                </p>
                <v-btn
                  icon
                  @click="
                    showOptions === company.id
                      ? (showOptions = 0)
                      : openOptions(company.id)
                  "
                >
                  <v-icon color="black">
                    {{
                      showOptions === company.id
                        ? "mdi-menu-up"
                        : "mdi-menu-down"
                    }}
                  </v-icon>
                </v-btn>
              </div>
              <div v-if="showOptions === company.id">
                <div
                  v-for="option in options"
                  :key="option.id"
                  class="contacts_item2"
                >
                  <p
                    class="contacts_text"
                    :class="checkOption(option.id) && 'filled'"
                  >
                    {{ option.name }}
                  </p>
                  <v-switch
                    :input-value="checkOption(option.id)"
                    color="#5d4bd0"
                    inset
                    hide-details
                    class="switch"
                    @change="changeCompanyOption(option.id)"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div class="contacts_item2">
              <p class="contacts_text" :class="yandexDelivery && 'filled'">
                YandexGo
              </p>
              <v-switch
                :input-value="yandexDelivery"
                color="#5d4bd0"
                inset
                hide-details
                class="switch"
                @change="changeYandexDelivery()"
              ></v-switch>
            </div>
          </div>
          <!-- зоны доставки -->
          <div class="contacts">
            <span class="title">Управление зонами доставки</span>
            <div v-for="zone in zones" :key="zone.id" class="contacts_item2">
              <p class="contacts_text" :class="checkZone(zone.id) && 'filled'">
                {{ zone.name }}
              </p>
              <v-switch
                v-model="zone.isOn"
                color="#5d4bd0"
                inset
                hide-details
                class="switch"
                @change="editZone(zone)"
              ></v-switch>
              <v-btn
                small
                icon
                color="error"
                class="contacts_icon"
                @click="deleteZone(zone.departmentZoneId)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
            <v-btn
              small
              rounded
              color="#5d4bd0"
              class="contacts_btn"
              @click="addZoneModal = true"
              >Добавить</v-btn
            >
          </div>
        </div>
        <div class="content_bot">
          <!-- лимиты филиала -->
          <div class="contacts input-type">
            <span class="title">Лимиты, платная доставка</span>
            <div class="contacts_item">
              <div>
                <p class="contacts_text">
                  Мин. сумма заказа: {{ limit.minOrderSum }} тенге
                </p>
                <p class="contacts_text">
                  Платная доставка:
                  {{ limit.isPaidDelivery ? "Включена" : "Отключена" }}
                </p>
                <div v-if="limit.isPaidDelivery">
                  <p class="contacts_text">
                    Мин. сумма для доставки:
                    {{ limit.minOrderSumDelivery }} тенге
                  </p>
                  <p class="contacts_text">
                    Радиус бесплатной доставки: {{ limit.freeRadius }} м.
                  </p>
                </div>
              </div>

              <v-btn icon large color="#5d4bd0" @click="showLimits = true"
                ><v-icon>mdi-pencil-circle</v-icon></v-btn
              >
            </div>
            <!-- <div v-if="changeLimit" class="contacts_changes">
              <v-text-field
                v-model="limit.minOrderSum"
                hide-details
                dense
                solo
                flat
                class="inputs"
              />
              <v-btn small         rounded
              color="#5d4bd0" class="contacts_btn" @click="changeDepLimit()"
                >Подтвердить</v-btn
              >
            </div> -->
          </div>
          <!-- Кнопка, раскрывающая выбор группы -->
                      
            <v-btn
              text
              small
              @click="openGroupsList()"
            >
              Управление группой филиала
            </v-btn>

            <!-- Выбор группы (по аналогии с providers) -->
            <div v-if="groupsListVisible" class="mt-3">
              <v-select
                v-model="selectedGroup"
                :items="DEPARTMENT_GROUPS"
                item-text="name"
                item-value="id"
                label="Выберите группу"
              ></v-select>

              <v-btn color="#5d4bd0" small dark class="mr-2" @click="saveGroup"
                >Сохранить</v-btn
              >
              <v-btn color="error" small dark @click="removeGroup"
                >Убрать из группы</v-btn
              >
            </div>

          <!-- время работы -->
          <div class="contacts">
            <span class="title">Время работы филиала</span>
            <div class="contacts_item">
              <v-btn
                small
                rounded
                color="#5d4bd0"
                class="contacts_btn"
                @click="
                  $router.push(`/parameters-time-control/${departmentId}`)
                "
                >Посмотреть</v-btn
              >
            </div>
          </div>
        </div>

        <!-- блок с картой -->
        <!-- <div class="content_map">
          <div class="content_map__search-block">
            <v-text-field
              v-model="searchText"
              color="#5d4bd0"
              label="Поиск по карте"
              single-line
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="content_map__input"
              @click:clear="searchText = ''"
            />
            <v-btn color="#5d4bd0" dark @click="searchAddress()"> Найти </v-btn>
            <v-btn v-if="adressIsChanged" @click="changeDepartment()">
              Изменить адрес
            </v-btn>
          </div>
          <yandex-map
            :controls="map.controls"
            :zoom="map.zoom"
            :settings="mapSettings"
            :coords="map.coords"
            :options="map.options"
            @click="mapClick"
            @map-was-initialized="myMap = $event"
          >
            <ymap-marker
              v-if="map.MarkerVisible"
              :coords="map.coords"
              marker-id="index"
            />
          </yandex-map>
        </div> -->
      </section>
    </v-card-text>

    <v-card-text v-else>
      <p>У данного филиала еще не создан профиль</p>
      <v-form ref="createForm">
        <div class="contacts_item">
          <p>Основная почта</p>
          <v-text-field
            v-model="createData.email"
            label="Почта"
            dense
            solo
            flat
            class="inputs"
            required
            :rules="[$validate.required]"
          />
        </div>
        <div class="contacts_item">
          <p>Почта для обратной связи и отправки ежедневных отчетов</p>
          <v-text-field
            v-model="createData.emailFeedback"
            label="Доп. почта"
            dense
            solo
            flat
            class="inputs"
            required
            :rules="[$validate.required]"
          />
        </div>
        <div class="contacts_item">
          <p>Телефон</p>
          <v-text-field
            v-model="createData.phoneNumber"
            label="Телефон"
            dense
            solo
            flat
            class="inputs"
            required
            :rules="[$validate.required]"
          />
        </div>
        <div class="checkboxes">
          <v-checkbox
            v-model="createData.selfDelivery"
            label="Своя доставка"
          ></v-checkbox>
          <v-checkbox
            v-model="createData.ayanDeliveryComposition"
            label="Склады и доставка Аяна"
          ></v-checkbox>
        </div>
      </v-form>
      <v-btn
        small
        rounded
        color="#5d4bd0"
        class="contacts_btn"
        @click="addProfile()"
        >Создать</v-btn
      >
    </v-card-text>
    <!-- Модалка с добавлением зоны к филиалу -->
    <AddZoneToDepartmentModal
      :modal.sync="addZoneModal"
      @update-zones="updateZones()"
    />
    <LimitModal
      :show-modal.sync="showLimits"
      :limit="limit"
      :edit-mode="limit.propertyId ? true : false"
      @createLimit="changeDepLimit()"
      @editLimit="changeDepLimit()"
    />
    <!-- модалка с заполнением реквизитов -->
    <Requisits
      :requisits-modal.sync="requisitsModal"
      :provider-id="departmentId"
      :requisits="requisits"
      :department="true"
      @editRequisits="
        getData();
        requisitsModal = false;
      "
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import LimitModal from "@/components/Limits/LimitModal";
import Diagrams from "@/components/Department/Diagrams";
import Requisits from "@/components/ProviderProfile/Requisits";
import RequisitsBlock from "@/components/ProviderProfile/RequisitsBlock";
import AddZoneToDepartmentModal from "@/components/ProviderProfile/AddZoneToDepartmentModal";
import showMessage from "@/Functions/message";
import api from "@/api";
export default {
  components: {
    Loader,
    Diagrams,
    LimitModal,
    Requisits,
    RequisitsBlock,
    AddZoneToDepartmentModal,
  },
  data() {
    return {
      myMap: {},
      loading: true,
      adressInp: {
        street: "",
        house: "",
        addressTypeId: 2,
      },
      // map: {
      //   zoom: 15,
      //   coords: [49.807754, 73.088504],
      //   controls: ["geolocationControl", "zoomControl"],
      //   MarkerVisible: true,
      //   options: {
      //     restrictMapArea: false,
      //   },
      // },
      // mapSettings: {
      //   apiKey: "4ca540e5-4dd9-43dd-b029-5d4a1097eec8",
      //   lang: "ru_RU",
      //   coordorder: "latlong",
      //   version: "2.1",
      // },
      profileData: {},
      showEditButton: false,
      departmentName: null,
      departmentId: null,
      yandexDelivery: false,
      searchText: "",
      adressIsChanged: false,
      limit: {
        minOrderSum: 0,
      },
      showLimits: false,
      requisitsModal: false,
      requisits: null,
      showOptions: 0,
      options: [],
      departmentOptions: [],
      addZoneModal: false,
      zones: [],
      createData: {},
      editTextarea: false,
      selectedGroup: null,
      groupsListVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      ADDRESS: "Address/STATE",
      COURIERS_ZONE: "Couriers/STATE",
      PAYMENT: "Payment/STATE",
      CITY: "City/STATE",
      DEPARTMENT: "Department/STATE",
      COMPANY: "Logistic/COMPANY",
      DEPARTMENT_GROUPS: "DepartmentGroups/STATE"
    }),
    currentGroupId() {
      return this.profileData?.categoryOwnership?.id || null;
    },
  },
  watch: {
    adressInp: {
      handler() {
        this.adressIsChanged = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.departmentId = this.$route.params.id;
    this.getData();
    this.GET_ALL_GROUPS();
  },
  methods: {
    ...mapActions({
      addDepartment: "Department/ADD_DEPARTMENT",
      getDepartment: "Department/GET_SINGLE",
      editDepartment: "Department/EDIT_DEPARTMENT",
      getAllPaymentTypes: "Payment/GET_ALL_PAYMENT",
      getLogisticCompany: "Logistic/GET_COMPANY",
      GET_ALL_GROUPS: "DepartmentGroups/GET_ALL_GROUPS",
      ADD_GROUP_TO_DEPARTMENT: "DepartmentGroups/ADD_GROUP_TO_DEPARTMENT",
      REMOVE_GROUP_FROM_DEPARTMENT: "DepartmentGroups/REMOVE_GROUP_FROM_DEPARTMENT",
    }),
      /// методы работы с категориями филиалов
    openGroupsList() {
      // например, при нажатии кнопки «Управление группой филиала»
      this.groupsListVisible = !this.groupsListVisible;
      // устанавливаем selectedGroup, если у филиала уже есть
      this.selectedGroup = this.currentGroupId;
    },
    async saveGroup() {
      if (!this.selectedGroup) {
        showMessage("Выберите группу");
        return;
      }
      const payload = {
        departmentId: this.$route.params.id,
        groupId: this.selectedGroup,
      };
      const response = await this.ADD_GROUP_TO_DEPARTMENT(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Филиал добавлен в группу", true);
        // обновить данные филиала, если нужно
        this.getData();
      }
      this.groupsListVisible = false;
    },
    async removeGroup() {
      const departmentId = this.$route.params.id;
      const response = await this.REMOVE_GROUP_FROM_DEPARTMENT(departmentId);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Филиал убран из группы", true);
        this.getData();
      }
      this.groupsListVisible = false;
    },
    ///
    highlightText(refName) {
      const input = this.$refs[refName].$el.querySelector("input");
      input.focus();
      input.select();
    },

    async getData() {
      const response = await api.Providers.getDepartmentProfile(
        this.departmentId
      );
      if (response.type === "error") {
        this.loading = false;
        return;
      }
      this.checkYandexDelivery();
      this.profileData = response;
      this.requisits = response.departmentRequisites;
      await this.getLogisticCompany();
      // если стейт пустой, подтягиваем все типы оплаты
      if (this.PAYMENT.allPayment.length === 0) {
        await this.getAllPaymentTypes();
      }
      this.departmentName = this.DEPARTMENT.cityDepartment.find(
        (department) => department.id == this.departmentId
      ).name;
      this.adressInp.street = response.address.street;
      this.adressInp.house = response.address.house;
      // чтобы отменить первые изменения watch
      setTimeout(() => (this.adressIsChanged = false), 0);
      // this.map.coords = response.address.geo.split(",");
      this.updateZones();
      let limitsResp = await api.Providers.getAyanLimits();
      const findLimit = limitsResp.find(
        (limit) => limit.departmentId == this.departmentId
      );
      if (findLimit) {
        this.limit = findLimit;
      }
      this.loading = false;
    },
    // изменение зон филиала
    async changeZone(zoneId) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      const payload = {
        departmentId: this.departmentId,
        deliveryZoneId: zoneId,
      };
      const response = await api.Department.setCouriersZone(payload);
      if (response.type !== "error") {
        showMessage("Зона привязана к филиалу", true);
        this.getData();
      } else {
        showMessage(response.data.message);
      }
    },

    updateZones() {
      // чтобы не тормозило страницу через then
      api.Zone.getDepZone(this.departmentId).then((zonesResp) => {
        if (zonesResp.type === "success") {
          this.zones = zonesResp.data;
        }
      });
    },
    async editZone(zone) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      const payload = {
        departmentId: this.departmentId,
        zoneId: zone.zoneId,
        isOn: zone.isOn,
        id: zone.departmentZoneId,
      };
      let response = await api.Zone.editDepZone(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage(zone.isOn ? "Зона активирована" : "Зона отключена", true);
      }
      this.updateZones();
    },
    async deleteZone(zoneId) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      let response = await api.Zone.deleteDepZone(zoneId);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Зона удалена", true);
        this.updateZones();
      }
    },
    // изменение данных о департаменте

    async editDepartmentFunc() {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      if (!this.$refs.depForm.validate()) {
        showMessage("Заполните все выделенные поля");
        return;
      }
      this.profileData.departmentId = this.profileData.department.id;
      const response = await api.Providers.editDepartmentProfile(
        this.profileData
      );
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        this.getData();
        this.showEditButton = false;
        this.editTextarea = false;
      }
    },
    // изменение лимитов
    async changeDepLimit() {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      let body = {
        propertyId: this.limit.propertyId,
        providerOrderLimitId: this.departmentId,
        departmentId: this.departmentId,
        ...this.limit,
      };
      let response;
      if (this.limit.propertyId) {
        response = await api.Providers.editLimitToAyan(body);
      } else {
        response = await api.Providers.addLimitToAyan(body);
      }
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.showLimits = false;
      }
    },
    // изменение типа оплаты
    async changePaymentType(id) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      const payload = {
        departmentId: this.departmentId,
        paymentTypeId: id,
      };
      let response;
      if (this.checkSwitch(id)) {
        response = await api.Payment.deleteDepartmentPayment(payload);
      } else {
        response = await api.Payment.addDepartmentPayment(payload);
      }
      if (response.type !== "error") {
        this.getData();
      } else {
        showMessage(response.data.message);
      }
    },
    // загрузка фото к филиалу
    uploadFoto(file) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      if (file.size > 1000000) {
        showMessage("Размер файла превышает 1мб");
      } else {
        if (!this.profileData.image) {
          this.profileData.image = {};
        }
        this.profileData.image.fileUrl = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("file", file);
        let payload = {
          providerId: this.departmentId,
          body: formData,
        };
        api.Providers.addImageToDepartment(payload);
      }
    },
    // изменение филиала
    async changeDepartment() {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      let adressId = await this.getAdressId();
      let payload = {
        id: this.profileData.department.id,
        name: this.departmentName,
        scheduleId: 1,
        addressId: adressId,
        cityId: this.$root.city,
        selfZoned: this.profileData.selfDelivery,
        isAyan: true, //не знаю за что отвечают эти данные
        delete: false, //не знаю за что отвечают эти данные
      };
      let response = await this.editDepartment(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        this.getData();
      }
    },
    // функции работы с яндекс доставкой
    // нахождение филиала в списке всех филиалов, и проверка есть ли у него яндекс доставка
    async checkYandexDelivery() {
      const yandexList = await api.Providers.getYandexList(this.departmentId);
      const curDep = yandexList.data.find(
        (dep) => dep.departmentId == this.departmentId
      );
      if (!curDep) {
        return;
      }
      if (curDep.propertyValue === "-1") {
        this.yandexDelivery = false;
      } else {
        this.yandexDelivery = true;
      }
    },
    // изменение яндекс доставки для филиала
    async changeYandexDelivery() {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      const response = await api.Providers.changeYandexDelivery(
        this.departmentId
      );
      if (response.type !== "error") {
        showMessage("Яндекс доставка успешно изменена", true);
        this.checkYandexDelivery();
      } else {
        showMessage(response.data.message);
      }
    },
    // блок вспомогательных функций
    // Вспомогательная функция получает id адреса
    async getAdressId() {
      let response = await api.Address.addAddress(this.adressInp);
      return response.data.id;
    },
    // находит есть ли данный тип оплаты у филиала
    checkSwitch(id) {
      return this.profileData.paymentTypes.some(
        (item) => item.paymentTypeId === id
      );
    },
    // находит есть ли данная зона у филиала
    checkZone(id) {
      if (this.profileData.deliveryZone) {
        return this.profileData.deliveryZone.id === id;
      }
      return false;
    },
    // откывает див с опциями данной компании
    async openOptions(companyId) {
      this.showOptions = companyId;
      const response = await api.Logistic.getOptions(companyId);
      this.options = response;
    },

    async changeCompanyOption(optionId) {
      if (this.$root.isProvider) {
        showMessage("Вы пока не можете изменять данные филиала");
        return;
      }
      let response;
      // если опция уже есть то удаляем
      if (this.checkOption(optionId)) {
        response = await api.Logistic.removeOptionFromDepartment(
          this.checkOption(optionId, true)
        );
      } else {
        const body = {
          departmentId: this.departmentId,
          deliveryOptionId: optionId,
        };
        response = await api.Logistic.addOptionToDepartment(body);
      }

      if (response.type !== "error") {
        showMessage("Опция изменена", true);
        const options = await api.Logistic.getDepartmentOptions(
          this.departmentId
        );
        this.profileData.deliveryOptions = options;
      } else {
        showMessage(response.data.message);
      }
    },
    // находит есть ли данная опция у филиала
    checkOption(id, returnId) {
      if (this.profileData.deliveryOptions) {
        const find = this.profileData.deliveryOptions.findIndex(
          (option) => option.deliveryOption.id === id
        );
        if (returnId) {
          return this.profileData.deliveryOptions[find].id;
        } else {
          return find >= 0;
        }
      }
      return false;
    },
    // Создает либо изменяет профиль
    async addProfile() {
      if (!this.$refs.createForm.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.createData.brandCount = 0;
      this.createData.departmentId = this.departmentId;
      let response = await api.Providers.createDepartmentProfile(
        this.createData
      );
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Профиль создан", true);
        await this.getData();
      }
    },

    // функции работы с картой, пока отключили, но не буду удалять могут и вернуть)
    // mapClick(e) {
    //   this.map.coords = e.get("coords");
    //   ymaps.geocode(this.map.coords).then((result) => {
    //     let adress = result.geoObjects.get(0).properties.get("text");
    //     this.searchText = adress;
    //     this.adressInp.street = this.getClearAddress(adress)[0];
    //     this.adressInp.house = this.getClearAddress(adress)[1];
    //   });
    // },
    // getClearAddress(data) {
    //   // Уберем минус слова и если улицы двойные разделенные через запятую, то уберем её
    //   let replaces_text = [
    //     "Казахстан, ",
    //     this.CITY.allCity[this.$root.city].name + ", ",
    //     "Карагандинская область, ",
    //     "Нур-Султан (Астана), ",
    //   ];
    //   for (let i = 0; i < replaces_text.length; i++) {
    //     data = data.replace(replaces_text[i], "");
    //   }
    //   let arr = data.split(",");
    //   let home = [];
    //   home[1] = arr.pop(); // Потому что сначала получим дом, потом улицу
    //   home[0] = arr.pop();
    //   return home;
    // },
    // searchAddress() {
    //   let activeCityTitle = this.CITY.allCity[this.$root.city].name;
    //   ymaps.geocode(`${activeCityTitle}, ${this.searchText}`).then((result) => {
    //     let adress = result.geoObjects.get(0).properties.get("text");
    //     this.adressInp.street = this.getClearAddress(adress)[0];
    //     this.adressInp.house = this.getClearAddress(adress)[1];
    //     let userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();
    //     this.map.coords = userCoodinates;
    //   });
    // },
  },
};
</script>
<style lang="scss">
@import "./style";
</style>
